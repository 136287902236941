.light .status {
    font-weight: bold;
}

.light .status.Active, .light .status.Success {
    color: #219A21;
}

.light .status.Disabled, .light .status.Failure {
    color: #CD5454;
}
