.footer {
    height: 56px;
}

.footer .bottom-nav {
    top: auto;
    bottom: 0;
}

.iphone-x .footer,
.iphone-x .footer header {
    height: 76px;
}

.MuiSnackbar-root {
    bottom: 64px !important;
}

.iphone-x .MuiSnackbar-root {
    bottom: 84px !important;
}

.footer .MuiBottomNavigation-root button:disabled {
    opacity: 0.5;
}
